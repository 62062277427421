import parseJson from './parseJson';

const replaceJSVersion = (url, nextVersion) =>
  url.replace(/https:\/\/[^/]+\/[\w-]+\/(\d+\.\d+\.\d+)\/static\/js\/main\.js/, (str, version) =>
    str.replace(version, nextVersion)
  );

const replaceCSSVersion = (url, nextVersion) =>
  url.replace(/\/\/[^/]+\/[\w\-/]+\/(\d+\.\d+\.\d+)\/static\/css\/main\.css/, (str, version) =>
    str.replace(version, nextVersion)
  );

const overrideMicroAppsServices = (services) => {
  const override = parseJson(window.localStorage.getItem('__BACKOFFICE_MICRO_APPS_OVERRIDE')) || {};

  Object.entries(override).forEach(([name, nextVersion]) => {
    if (!nextVersion) {
      return;
    }

    const service = services[name];
    const timestamp = Date.now();

    services[name] = {
      ...service,
      js: service.js.map((url) => `${replaceJSVersion(url, nextVersion)}?${timestamp}`),
      css: service.css.map((url) => `${replaceCSSVersion(url, nextVersion)}?${timestamp}`),
    };
  });

  return services;
};

export default overrideMicroAppsServices;
