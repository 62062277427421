import registerServices from '@backoffice/register-services';
import overrideMicroAppsServices from './utils/overrideMicroAppsServices';

let bootstrap = (onAfterBootstrap) => {
  window.backoffice_core = {
    getLanguage: () => 'en',
    assetsBaseUrl: 'https://nas-backoffice.hrzn.io/assets',
  };

  if (typeof onAfterBootstrap !== 'function') {
    throw new Error('bootstrap: "onAfterBootstrap" should be a function');
  }

  if (!window?.__APP__?.services?.auth) {
    throw new Error('bootstrap: Component config missed');
  }

  registerServices(
    overrideMicroAppsServices({
      ...window?.__APP__?.services,
    })
  );

  onAfterBootstrap();
};

export default bootstrap;
