import React from 'react';
import ReactDOM from 'react-dom/client';
import '@backoffice/ui/dist/main.css';
import bootstrap from './bootstrap';
import reportWebVitals from './reportWebVitals';
import App from './App';

bootstrap(() => {
  ReactDOM.createRoot(document.getElementById('root')).render(<App />);

  reportWebVitals();
});
